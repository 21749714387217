import type { LinksFunction } from "@remix-run/node"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"

import { LoaderResponse } from "@iyk/remix"
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  useLoaderData,
  useRouteError,
  type ShouldRevalidateFunction,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix"
import { Effect, Option } from "effect"
import { Remix } from "./main.server.ts"
import { ClientScript } from "./observability/Sentry.tsx"

import "./ui/styles.css"

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  return false
}

export const links: LinksFunction = () => [
  ...UI.favicons,
  {
    rel: "manifest",
    href: "/site.webmanifest",
  },
]

export const loader = Remix.loader.handler(({ capabilities: { config } }) =>
  Effect.succeed(LoaderResponse.Ok({ sentryConfig: Option.getOrNull(config.sentry) })),
)

const App = () => {
  const { sentryConfig } = useLoaderData<typeof loader>()

  return (
    <html className="light bg-background">
      <head>
        <meta name="viewport" content="width=device-width" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <UI.Toaster />
        <Scripts />
        {sentryConfig && <ClientScript dsn={sentryConfig.dsn} environment={sentryConfig.environment} />}
      </body>
    </html>
  )
}

export default withSentry(App)

export const ErrorBoundary = () => {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex flex-col items-center gap-6">
          <Icon.InfoCircled />

          <AppError />
        </div>
        <Scripts />
      </body>
    </html>
  )
}

const AppError = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex flex-col items-center">
        <h1 className="text-lg">
          {error.status} {error.statusText}
        </h1>
        <p>
          <pre>{JSON.stringify(error.data, null, 2)}</pre>
        </p>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-lg">Internal Server Error</h1>
    </div>
  )
}
